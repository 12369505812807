var columns = [{
  title: 'UID',
  dataIndex: 'userid',
  key: 'userid',
  scopedSlots: {
    customRender: 'userid'
  }
}, {
  title: '商家名称',
  dataIndex: 'userName',
  key: 'userName',
  // width: '7%',
  scopedSlots: {
    customRender: 'userName'
  }
}, {
  title: '商家类型',
  dataIndex: 'userTypeName',
  key: 'userTypeName',
  // width: '5%',
  scopedSlots: {
    customRender: 'userTypeName'
  }
}, {
  // title: '邮箱',
  dataIndex: 'userEmail',
  key: 'userEmail',
  // width: '10%',
  scopedSlots: {
    customRender: 'userEmail'
  },
  slots: {
    title: 'emailTit'
  }
}, {
  title: '商品',
  dataIndex: 'url',
  key: 'url',
  width: 200,
  scopedSlots: {
    customRender: 'url'
  }
}, {
  title: '事项',
  dataIndex: 'typeName',
  key: 'typeName',
  // width: '10%',
  scopedSlots: {
    customRender: 'typeName'
  }
}, {
  title: '保证金交易额（$）',
  dataIndex: 'bond',
  key: 'bond',
  // width: '10%',
  scopedSlots: {
    customRender: 'bond'
  }
},
// {
//     title: '收单费($)',
//     dataIndex: 'receipt',
//     key: 'receipt',
//     // width: '10%',
//     scopedSlots: {
//         customRender: 'receipt'
//     }
// },
// {
//     title: '支付金额($)',
//     dataIndex: 'amount',
//     key: 'amount',
//     // width: '10%',
//     scopedSlots: {
//         customRender: 'amount'
//     }
// },
{
  title: '保证金余额($)',
  dataIndex: 'availablebond',
  key: 'availablebond',
  // width: '10%',
  scopedSlots: {
    customRender: 'availablebond'
  }
}, {
  title: '订单号',
  dataIndex: 'orderno',
  key: 'orderno',
  // width: '10%',
  scopedSlots: {
    customRender: 'orderno'
  }
}, {
  title: '第三方流水号',
  dataIndex: 'tradeno',
  key: 'tradeno',
  // width: '10%',
  scopedSlots: {
    customRender: 'tradeno'
  }
}, {
  title: '时间',
  dataIndex: 'createtime',
  key: 'createtime',
  // width: '5%',
  scopedSlots: {
    customRender: 'createtime'
  }
}];
export { columns };
var searchKeys = [{
  key: 'dates',
  label: '时间',
  format: "YYYY-MM-DD HH:mm",
  placeholder: ["开始时间", "截止时间"],
  dates: true,
  rules: [{
    type: 'array',
    required: false,
    message: '请选择时间段!'
  }]
}, {
  key: "orderNo",
  label: "订单号",
  placeholder: "订单号",
  required: false,
  rules: [],
  input: true
}, {
  key: "type",
  label: "商家类型",
  placeholder: "全部",
  required: false,
  rules: [],
  select: true
}, {
  key: "user",
  label: "商家",
  placeholder: "名称/邮箱",
  required: false,
  rules: [],
  input: true
}, {
  key: "sku",
  label: "货号",
  placeholder: "全部",
  required: false,
  rules: [],
  input: true
}, {
  key: "userId",
  label: "UID",
  placeholder: "UID",
  required: false,
  rules: [],
  input: true
}, {
  key: "tradeNo",
  label: "第三方流水号",
  placeholder: "第三方流水号",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };